import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogData, DIALOG_TYPE } from './confirmation-dialog.model';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'friskus-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public pdfUrl: SafeResourceUrl | null = null;
  public pdfDialog: DIALOG_TYPE | null = null;
  public dialogType = DIALOG_TYPE;
  public dialogData: ConfirmationDialogData = {
    type: this.dialogType.DELETE_PHOTO
  };


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData,
    private readonly dialog: MatDialog,
    private readonly sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.dialogData = Object.assign({}, this.dialogData, data);
    }
  }
  private readonly PDF_URLS = {
    PRIVACY_POLICY: 'https://friskusas.no/wp-content/uploads/2024/11/2024-10-10_personvernerklaering-friskus.pdf',
    DPA: 'https://friskusas.no/wp-content/uploads/2024/11/2024-06-19_databehandleravtale-arrangorer.pdf'
  };

  ngOnInit() {
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public openPdf(urlKey: string): void {
    const urlMapping = {
      privacyPolicy: this.PDF_URLS.PRIVACY_POLICY,
      dpa: this.PDF_URLS.DPA
    };

    if (!urlMapping[urlKey]) {
      console.error(`Invalid URL key: ${urlKey}`);
      return;
    }

    const dialogMapping = {
      privacyPolicy: DIALOG_TYPE.IFRAME_PRIVACY_POLICY_PDF,
      dpa: DIALOG_TYPE.IFRAME_DPA_PDF
    };

    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlMapping[urlKey]);
    this.pdfDialog = dialogMapping[urlKey] || null;

    const data: ConfirmationDialogData = {
      type: this.pdfDialog,
    };

    this.dialog.open(ConfirmationDialogComponent, {
      data,
      maxWidth: '90vw',
      width: '90vw',
      panelClass: 'full-width-dialog',
    });
  }
}
